import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";

import App from "App";

import "./index.css";

if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
  ReactGA.initialize("UA-101333629-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(<App />, document.getElementById("root"));
