import React from "react";
import { Code, FileText, Monitor, Video } from "react-feather";
import styled from "styled-components";

import { PageLink } from "constants/Mixins";

type ProjectProps = {
  title: string;
  description: string;
  tags?: string[];
  tagColors?: string[];
  demoUrl?: string;
  codeUrl?: string;
  paperUrl?: string;
  talkUrl?: string;
  margin?: string;
};

const Project = ({
  title,
  description,
  tags = [],
  tagColors = [],
  demoUrl,
  codeUrl,
  paperUrl,
  talkUrl,
  margin = "48px 0 0 0",
}: ProjectProps) => (
  <ProjectWrapper margin={margin}>
    <Column style={{ marginRight: "60px" }}>
      <Title>{title}</Title>
      <Tags>
        {tags.map((tag, idx) => (
          <Tag color={tagColors[idx]}>{tag}</Tag>
        ))}
      </Tags>
    </Column>
    <Column>
      <Description>{description}</Description>
      <Icons>
        {demoUrl && (
          <Icon>
            <Monitor size={16} />
            <PageLink href={demoUrl} target="_blank" rel="noopener noreferrer">
              Demo
            </PageLink>
          </Icon>
        )}
        {codeUrl && (
          <Icon>
            <Code size={16} />
            <PageLink href={codeUrl} target="_blank" rel="noopener noreferrer">
              Code
            </PageLink>
          </Icon>
        )}
        {paperUrl && (
          <Icon>
            <FileText size={16} />
            <PageLink href={paperUrl} target="_blank" rel="noopener noreferrer">
              Paper
            </PageLink>
          </Icon>
        )}
        {talkUrl && (
          <Icon>
            <Video size={16} />
            <PageLink href={talkUrl} target="_blank" rel="noopener noreferrer">
              Talk
            </PageLink>
          </Icon>
        )}
      </Icons>
    </Column>
  </ProjectWrapper>
);

export default Project;

const ProjectWrapper = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin};
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile}px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  display: flex;
  font-weight: 700;
  color: ${({ theme }) => theme.text};
  margin-bottom: 16px;
`;

const Tags = styled.div`
  width: 180px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile}px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const Tag = styled.div<{ color: string }>`
  position: relative;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  padding: 1px 4px;
  max-width: fit-content;
  color: ${({ theme }) => theme.dark1};
  background-color: ${({ color }) => color};
  border-radius: 4px;
  white-space: nowrap;
  margin-right: 4px;
  margin-bottom: 4px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${({ theme }) => theme.white}80;
    z-index: -1;
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.text};
  margin-bottom: 16px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Icon = styled.div`
  margin-right: 24px;
  align-items: center;
  display: flex;

  svg {
    margin-right: 4px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile}px) {
    margin-bottom: 8px;
  }
`;
