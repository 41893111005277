import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import HomePage from "pages/HomePage";
import NotFoundPage from "pages/NotFoundPage";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route component={NotFoundPage} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
