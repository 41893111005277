import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";

import Theme from "constants/Theme";
import { ThemeContext, ThemeType } from "context/ThemeContext";
import Routes from "Routes";

const THEME_KEY = "theme";
const currentTheme = () => {
  const stored = localStorage.getItem(THEME_KEY);
  if (stored === "light" || stored === "dark") {
    return stored;
  }
  return "light";
};

const GlobalStyle = createGlobalStyle`
  body, html {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.background};
  }
`;

const App = () => {
  useEffect(() => {}, []);

  const [theme, setTheme] = useState<ThemeType>(currentTheme());
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem(THEME_KEY, newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={theme === "light" ? Theme.light : Theme.dark}>
        <GlobalStyle />
        <Body>
          <Routes />
        </Body>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default App;

const Body = styled.div`
  min-height: 100vh;
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.background};
  transition: 0.2s background ease-in-out;
`;
